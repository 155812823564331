import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Tabs, Col, Table, Drawer, Button, Input, Select, message, Modal, Form } from "antd";
import { PlusOutlined, CloseOutlined, CloseSquareFilled, MinusOutlined, WarningOutlined } from "@ant-design/icons";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { Scrollbars } from "react-custom-scrollbars";
import Keyboard from "react-simple-keyboard";
import Axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import data from "../../constants/retailPos.json";
import BarCodeComponentForRetail from "../../lib/BarCodeComponentForRetail";
import SalesHistoryIcon from "../PointOfSale/Retail/SvgComponent/SalesHistoryIcon";
import BOMOrderHistory from "./BOMOrderHistory";
import db from "../../database";
import BOMModals from "./BOMModals";
import POSLogo from "../../assets/images/NewLogoCW.svg";
import sideMenu from "../../assets/images/side-menu.svg";
import userIcon from "../../assets/images/userId.svg";
import Online from "../../assets/images/green.svg";
import Ofline from "../../assets/images/red.svg";
import SearchIcon from "../../assets/images/searchIcon.svg";
import Close from "../../assets/images/close-x.svg";
import DeleteProduct from "../../assets/images/delete1.svg";
import DefaultProductImage from "../../assets/images/no-image.svg";
import CancelArrow from "../../assets/images/cancelArrow.svg";
import NotFoundImg from "../../assets/images/NotFoundImg.svg";
import CustomerSearch from "../../assets/images/c-serach.svg";
import unlinkTill from "../../assets/images/unlink-icon.svg";
import Logout from "../../assets/images/logout-new.svg";
import LockIcon from "../../assets/images/lock-icon.svg";
import LockScreen from "../../assets/images/lockScreen.svg";

const ProductionOrder = () => {
  const { Option } = Select;
  const { access_token, username } = getOAuthHeaders();
  const history = useHistory();
  const genericUrl = process.env.REACT_APP_genericUrl;
  const retailUrl = process.env.REACT_APP_serverUrl;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const domainURL = process.env.REACT_APP_domain;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const posConfigData = JSON.parse(localStorage.getItem("posConfigData"));
  const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;
  const [chooseProduct, setChooseProduct] = useState(false);
  const [tillLink, setTillLink] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [productSearchInput, setProductSearchInput] = useState("");
  const [quantityInputValue, setQuantityInputValue] = useState(() => {
    const value = localStorage.getItem("quantityInputValue");
    if (value) {
      return parseInt(value);
    } else {
      return 1;
    }
  });
  const [keyBoardInputValue, setKeyBoardInputValue] = useState(() => {
    const value = localStorage.getItem("quantityInputValue");
    if (value) {
      return parseInt(value);
    } else {
      return 1;
    }
  });
  const [isFirstInput, setIsFirstInput] = useState(true);
  const [selectedKey, setSelectedKey] = useState(null);
  const [isNewInput, setIsNewInput] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(() => {
    const product = localStorage.getItem("selectedProduct");
    if (product) {
      return JSON.parse(product);
    } else {
      return null;
    }
  });
  const [selectedProductSalePrice, setSelectedProductSalePrice] = useState(() => {
    const price = localStorage.getItem("selectedProductSalePrice");
    if (price) {
      return parseFloat(price);
    } else {
      return 0;
    }
  });
  const [chooseProductsData, setChooseProductsData] = useState([]);
  const [formattedDate, setFormattedDate] = useState(() => {
    const date = localStorage.getItem("formattedDate");
    if (date) {
      return date;
    } else {
      return "";
    }
  });
  const [searchedProduct, setSearchedProduct] = useState("");
  const [chooseSearchedProduct, setChooseSearchedProduct] = useState("");
  const [searchedProductsData, setSearchedProductsData] = useState(() => {
    const data = localStorage.getItem("searchedProductsData");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  });
  const [searchedProductsFlag, setSearchedProductsFlag] = useState(false);
  const [searchedProductsDrawerData, setSearchedProductsDrawerData] = useState([]);
  const [subtotal, setSubtotal] = useState(() => {
    const price = localStorage.getItem("subtotal");
    if (price) {
      return parseFloat(price);
    } else {
      return 0;
    }
  });
  const [totalItems, setTotalItems] = useState(() => {
    const count = localStorage.getItem("totalItems");
    if (count) {
      return parseInt(count);
    } else {
      return 0;
    }
  });
  const [totalQty, setTotalQty] = useState(() => {
    const qty = localStorage.getItem("totalQty");
    if (qty) {
      return qty;
    } else {
      return 0;
    }
  });
  const [totalSalePrice, setTotalSalePrice] = useState(() => {
    const price = localStorage.getItem("totalSalePrice");
    if (price) {
      return parseFloat(price);
    } else {
      return 0;
    }
  });
  const [showRemarks, setShowRemarks] = useState(false);
  const [otherCharges, setOtherCharges] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [remarks, setRemarks] = useState(() => {
    const text = localStorage.getItem("remarks");
    if (text) {
      return text;
    } else {
      return "";
    }
  });
  const [showInputFields, setShowInputFields] = useState(false);
  const [QtyShowInputFields, setQtyShowInputFields] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [productionTypeData, setProductionTypeData] = useState([]);
  const [productionPrefix, setProductionPrefix] = useState(() => {
    const prefix = localStorage.getItem("productionPrefix");
    if (prefix !== "null") {
      return prefix;
    } else {
      return "";
    }
  });
  const [productionNextNo, setProductionNextNo] = useState(() => {
    const nextNo = localStorage.getItem("productionNextNo");
    if (nextNo) {
      return nextNo;
    } else {
      return "";
    }
  });
  const [productionName, setProductionName] = useState(() => {
    const name = localStorage.getItem("productionName");
    if (name) {
      return name;
    } else {
      return "";
    }
  });
  const [productionDocId, setProductionDocId] = useState(() => {
    const docId = localStorage.getItem("productionDocId");
    if (docId) {
      return docId;
    } else {
      return "";
    }
  });
  const [otherChargesData, setOtherChargesData] = useState(() => {
    const data = localStorage.getItem("otherChargesData");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  });
  const [tempOtherChargesData, setTempOtherChargesData] = useState([]);
  const [pricingMethod, setPricingMethod] = useState(() => {
    const method = localStorage.getItem("pricingMethod");
    if (method) {
      return method;
    } else {
      return "";
    }
  });
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [reverseOrderData, setReverseOrderData] = useState(() => {
    const data = localStorage.getItem("reverseOrderData");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  });
  const [isStockModal, setIsStockModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [stockValidationData, setStockValidationData] = useState([]);
  const [disableSearch, setDisableSearch] = useState(() => {
    const flag = localStorage.getItem("disableSearch");
    if (flag) {
      return true;
    } else {
      return false;
    }
  });
  const [tempRemarks, setTempRemarks] = useState("");
  const [selectedTotalQuantity, setSelectedTotalQuantity] = useState(0);
  const [tempQuantityInputValue, setTempQuantityInputValue] = useState(() => {
    const value = localStorage.getItem("quantityInputValue");
    if (value) {
      return parseInt(value);
    } else {
      return 1;
    }
  });
  const [totalWeights, setTotalWeights] = useState(0);
  const [isWeightPopupVisible, setIsWeightPopupVisible] = useState(false);
  const [tempSelectProduct, setTempSelectProduct] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [isLocked, setIsLocked] = useState(false);
  const hasReverseOrderData = reverseOrderData && reverseOrderData.length > 0;
  const inputRef = useRef(null);
  const totalQtyRef = useRef(null);
  const qtyInputRef = useRef(null);
  const qtyRef = useRef(null);
  const firstInputRef = useRef(null);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const csBunitId = tillData.tillAccess.csBunit.csBunitId;
  const pricePrecision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const { TabPane } = Tabs;
  const rightRef = useRef(null);
  const textAreaRef = useRef(null);
  const keyboardRef = useRef(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  useEffect(() => {
    if (otherCharges) {
      setTimeout(() => {
        if (firstInputRef.current) {
          firstInputRef.current.focus();
          firstInputRef.current.select();
        }
      }, 0);
    }
  }, [otherCharges]);

  useEffect(() => {
    if (rightRef.current) {
      rightRef.current.focus();
    }
  }, [keyBoardInputValue]);

  useEffect(() => {
    if (qtyRef.current) {
      qtyRef.current.focus();
    }
  }, [selectedQuantity]);

  useEffect(() => {
    if (QtyShowInputFields && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [QtyShowInputFields]);

  useEffect(() => {
    if (showInputFields) {
      requestAnimationFrame(() => {
        qtyInputRef.current?.focus();
        qtyInputRef.current?.select();
        totalQtyRef.current?.focus();
        totalQtyRef.current?.select();
      });
    }
  }, [showInputFields]);

  useEffect(() => {
    if (showRemarks && textAreaRef.current) {
      setTimeout(() => textAreaRef.current.focus(), 0);
    }
    const amountInput = document.getElementById("sm-notes-input");
    if (amountInput) {
      amountInput?.focus();
    }
  }, [showRemarks]);

  useEffect(async () => {
    if (reverseOrderData?.length > 0) {
      if (reverseOrderData[0]?.indirectCost !== null) {
        let tempArray = [];
        reverseOrderData[0]?.indirectCost.forEach((item) => {
          tempArray.push({
            name: item?.prIndirectcostName,
            weight: (item?.value / reverseOrderData[0]?.qtyproduction) * reverseOrderData[0]?.qtyToReverse,
            pr_indirectcost_id: item?.prIndirectcostId,
          });
        });
        setOtherChargesData(tempArray);
      }
      setSelectedProduct({
        name: reverseOrderData[0]?.productName,
        m_product_id: reverseOrderData[0]?.mProductId,
        value: reverseOrderData[0]?.productValue,
      });
      setTotalSalePrice(reverseOrderData[0]?.salePrice);
      const currentDate = new Date();
      const formattedDate = currentDate.getDate().toString().padStart(2, "0") + "/" + (currentDate.getMonth() + 1).toString().padStart(2, "0") + "/" + currentDate.getFullYear();
      setFormattedDate(formattedDate);
      setRemarks(reverseOrderData[0]?.description);
      setQuantityInputValue(reverseOrderData[0]?.qtyToReverse);
      setTempQuantityInputValue(reverseOrderData[0]?.qtyToReverse);
      let tempArray = [];
      reverseOrderData[0]?.ioproducts?.forEach((product, index) => {
        tempArray.push({
          key: index,
          cs_uom_id: product.csUomId,
          uomname: product.uom,
          product_name: product.product,
          m_product_id: product.mProductId,
          ediCode: product.ediCode,
          weight: ((product.actualqty / reverseOrderData[0]?.qtyproduction) * reverseOrderData[0]?.qtyToReverse) / reverseOrderData[0]?.qtyToReverse,
          totalWeight: (product.actualqty / reverseOrderData[0]?.qtyproduction) * reverseOrderData[0]?.qtyToReverse,
          stock: product?.stockOnHand !== null ? product.stockOnHand : 0,
          sale_price: product.salePrice,
          total: (product.totalPrice / reverseOrderData[0]?.qtyproduction) * reverseOrderData[0]?.qtyToReverse,
        });
      });
      const modifiedData = await Promise.all(tempArray.map((product) => updateProductWithUOMData(product)));
      updateCartTotals(modifiedData);
      setSearchedProductsData(modifiedData);
    }
  }, [reverseOrderData]);

  const notFoundModal = () => {
    Modal.info({
      icon: null,
      okButtonProps: { style: { display: "none" } },
      okText: "Go Back",
      content: (
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            fontSize: "1vw",
          }}
        >
          <img src={NotFoundImg} alt="Not Found" style={{ width: "120px", height: "100px", marginBottom: "5px", marginTop: "10px" }} />
          <p style={{ margin: "5px 0", fontSize: "2em", fontWeight: "600", color: "#0F0718" }}>Product Not Found</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5" }} />
          <p style={{ color: "#0F0718", fontWeight: 400, fontSize: "1.25em", padding: "15px 15px 0 15px" }}>
            The scanned product is not in the system. Please check the barcode or enter the item manually.
          </p>
        </div>
      ),
      style: {
        textAlign: "center",
        height: "50vh",
        width: "50%",
      },
      bodyStyle: {
        padding: "0",
      },
      closable: false,
      onOk: null,
    });
    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  };

  useEffect(() => {
    if (chooseProduct) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [chooseProduct]);

  useEffect(() => {
    db.docTypesData.toArray().then((docTypes) => {
      const requiredDocTypes = docTypes.filter((docType) => docType?.doccategory === "BOM");
      setProductionTypeData(requiredDocTypes);
      setProductionDocId(requiredDocTypes[0]?.cs_doctype_id);
      setProductionName(requiredDocTypes[0]?.name);
      setProductionPrefix(requiredDocTypes[0]?.prefix);
      setProductionNextNo(requiredDocTypes[0]?.nextno);
    });
  }, []);

  const savingTotalSalePrice = (price) => {
    localStorage.setItem("totalSalePrice", price);
  };

  useEffect(() => {
    if (reverseOrderData?.length === 0) {
      const totalWeights = otherChargesData?.reduce((acc, item) => acc + (parseFloat(item.weight) || 0), 0) || 0;
      let totalSalePrice = pricingMethod === "Standard Price" ? selectedProductSalePrice : selectedProductSalePrice * quantityInputValue + subtotal;
      setTotalSalePrice(pricingMethod === "Standard Price" ? totalSalePrice : totalSalePrice / quantityInputValue);
      setTotalWeights(totalWeights);
      savingTotalSalePrice(pricingMethod === "Standard Price" ? totalSalePrice : totalSalePrice / quantityInputValue);
    }
  }, [selectedProductSalePrice, subtotal, otherChargesData, pricingMethod, quantityInputValue]);

  const updateQuantityAndProducts = (newQuantity) => {
    console.log(newQuantity);
    const modifiedProductData = searchedProductsData.map((product) => ({
      ...product,
      totalWeight: product.weight * newQuantity,
      total: product.weight * newQuantity * product.sale_price,
    }));
    let stockFlag = checkStockData(modifiedProductData);
    if (!stockFlag) {
      addOrUpdateProducts(modifiedProductData);
      setQuantityInputValue(newQuantity);
      setKeyBoardInputValue(newQuantity);
      setTempQuantityInputValue(newQuantity);
      savingQuantityInput(newQuantity);
    } else {
      setIsVisible(true);
      setKeyBoardInputValue(quantityInputValue);
      setTempQuantityInputValue(quantityInputValue);
    }
  };

  useEffect(() => {
    if (otherCharges) {
      setTempOtherChargesData([...otherChargesData]);
    }
  }, [otherCharges, otherChargesData]);

  const savingUpdateCartTotals = (itemsCount, qtyCount, subtotal) => {
    localStorage.setItem("totalItems", itemsCount);
    localStorage.setItem("totalQty", qtyCount);
    localStorage.setItem("subtotal", subtotal);
  };

  const updateCartTotals = (updatedItems) => {
    if (!Array.isArray(updatedItems)) {
      updatedItems = [];
    }
    let totalItemsCount = updatedItems.length;
    let totalQtyCount = updatedItems.reduce((acc, product) => acc + (product.totalWeight || 0), 0);
    let subtotalAmount = updatedItems.reduce((acc, product) => acc + (product.total || 0), 0);
    setTotalItems(totalItemsCount);
    setTotalQty(totalQtyCount);
    setSubtotal(subtotalAmount);
    savingUpdateCartTotals(totalItemsCount, totalQtyCount, subtotalAmount);
  };

  const checkStockData = (products) => {
    const productsWithInsufficientStock = products?.filter((bomProduct) => {
      const quantity = bomProduct?.totalWeight;
      const stock = bomProduct?.stock !== undefined ? bomProduct.stock : bomProduct?.stockOnHand;
      return quantity > stock;
    });
    setStockValidationData(productsWithInsufficientStock);
    return productsWithInsufficientStock?.length > 0 || false;
  };

  const handleAmount = (value) => {
    const amountInput = document.getElementById("sm-amount-input");
    if (amountInput) {
      amountInput?.focus();
    }
    const index = searchedProductsData?.findIndex((item) => item.key === selectedKey);
    if (value === "x") {
      setSelectedQuantity((prev) => {
        const newValue = prev.toString().slice(0, -1);
        return newValue === "" ? "" : newValue;
      });
    } else if (value === ".") {
      setSelectedQuantity((prev) =>
        searchedProductsData[index] &&
        Array.isArray(searchedProductsData[index]?.uomData) &&
        searchedProductsData[index]?.uomData[0] &&
        searchedProductsData[index]?.uomData[0].decimal === "Y"
          ? prev + "."
          : prev
      );
    } else {
      if (isNewInput) {
        setSelectedQuantity(parseInt(value));
        setIsNewInput(false);
      } else {
        setSelectedQuantity((prev) => {
          const isDecimalAllowed =
            searchedProductsData[index] &&
            Array.isArray(searchedProductsData[index]?.uomData) &&
            searchedProductsData[index]?.uomData[0] &&
            searchedProductsData[index]?.uomData[0].decimal === "Y";
          if (isDecimalAllowed) {
            return prev + value;
          } else {
            const updatedQuantity = prev + value;
            return updatedQuantity.toString().length <= 4 ? updatedQuantity : prev;
          }
        });
      }
    }
  };

  const handleTotalAmount = (value) => {
    const amountInput = document.getElementById("sm-total-amount-input");
    if (amountInput) {
      amountInput?.focus();
    }
    const index = searchedProductsData?.findIndex((item) => item.key === selectedKey);
    if (value === "x") {
      setSelectedTotalQuantity((prev) => {
        const newValue = prev.toString().slice(0, -1);
        return newValue === "" ? "" : newValue;
      });
    } else if (value === ".") {
      setSelectedTotalQuantity((prev) =>
        searchedProductsData[index] &&
        Array.isArray(searchedProductsData[index]?.uomData) &&
        searchedProductsData[index]?.uomData[0] &&
        searchedProductsData[index]?.uomData[0].decimal === "Y"
          ? prev + "."
          : prev
      );
    } else {
      if (isNewInput) {
        setSelectedTotalQuantity(parseInt(value));
        setIsNewInput(false);
      } else {
        setSelectedTotalQuantity((prev) => {
          const isDecimalAllowed =
            searchedProductsData[index] &&
            Array.isArray(searchedProductsData[index]?.uomData) &&
            searchedProductsData[index]?.uomData[0] &&
            searchedProductsData[index]?.uomData[0].decimal === "Y";
          if (isDecimalAllowed) {
            return prev + value;
          } else {
            const updatedQuantity = prev + value;
            return updatedQuantity.toString().length <= 4 ? updatedQuantity : prev;
          }
        });
      }
    }
  };

  const handleAmountQty = (value) => {
    if (value === "x") {
      setKeyBoardInputValue((prev) => {
        const newValue = prev.toString().slice(0, -1);
        return newValue === "" ? "" : newValue;
      });
    } else if (value === ".") {
      setKeyBoardInputValue((prev) =>
        selectedProduct && Array.isArray(selectedProduct.uomData) && selectedProduct.uomData[0] && selectedProduct.uomData[0].decimal === "Y" ? prev + "." : prev
      );
    } else {
      if (isFirstInput) {
        setKeyBoardInputValue(parseInt(value));
        setIsFirstInput(false);
      } else {
        setKeyBoardInputValue((prev) => {
          const isDecimalAllowed = selectedProduct && Array.isArray(selectedProduct.uomData) && selectedProduct.uomData[0] && selectedProduct.uomData[0].decimal === "Y";
          if (isDecimalAllowed) {
            return prev + value;
          } else {
            const updatedQuantity = prev + value;
            return updatedQuantity.toString().length <= 4 ? updatedQuantity : prev;
          }
        });
      }
    }
    const amountInput = document.getElementById("sm-qty-input");
    if (amountInput) {
      amountInput?.focus();
    }
  };

  const handleEnter = async () => {
    if (selectedKey !== null) {
      let updatedItems = searchedProductsData?.map((item) => {
        if (item.key === selectedKey) {
          let obj = {
            ...item,
            weight: selectedQuantity === "" ? item.weight : parseFloat(selectedQuantity),
            totalWeight: selectedQuantity === "" ? item.totalWeight : parseFloat(selectedQuantity) * (quantityInputValue === "" ? 1 : quantityInputValue),
            total: selectedQuantity === "" ? item.total : parseFloat(selectedQuantity) * (quantityInputValue === "" ? 1 : quantityInputValue) * item.sale_price,
          };
          return obj;
        } else {
          return item;
        }
      });
      const stockFlag = checkStockData([updatedItems[selectedKey]]);
      if (stockFlag) {
        setIsVisible(true);
      } else {
        await addOrUpdateProducts(updatedItems);
        setIsNewInput(true);
        setSelectedRowKey(null);
        setShowInputFields(false);
        setQtyShowInputFields(false);
        setSelectedRowKey(null);
      }
    }
  };

  const handleTotalEnter = async () => {
    if (selectedKey !== null) {
      let updatedItems = searchedProductsData?.map((item) =>
        item.key === selectedKey
          ? {
              ...item,
              weight: selectedTotalQuantity === "" ? item.weight : parseFloat(selectedTotalQuantity) / quantityInputValue,
              totalWeight: selectedTotalQuantity === "" ? item.totalWeight : parseFloat(selectedTotalQuantity),
              total: selectedTotalQuantity === "" ? item.total : parseFloat(selectedTotalQuantity) * item.sale_price,
            }
          : item
      );
      const stockFlag = checkStockData([updatedItems[selectedKey]]);
      if (stockFlag) {
        setIsVisible(true);
      } else {
        await addOrUpdateProducts(updatedItems);
        setIsNewInput(true);
        setSelectedRowKey(null);
        setShowInputFields(false);
        setQtyShowInputFields(false);
        setSelectedRowKey(null);
      }
    }
  };

  const handleEnterQty = () => {
    let modifiedProductData = searchedProductsData.map((product) => ({
      ...product,
      totalWeight: product.weight * (keyBoardInputValue === "" ? 1 : keyBoardInputValue),
      total: product.weight * (keyBoardInputValue === "" ? 1 : keyBoardInputValue) * product.sale_price,
    }));
    let stockFlag = checkStockData(modifiedProductData);
    if (stockFlag) {
      setIsVisible(true);
      setKeyBoardInputValue(quantityInputValue);
      setTempQuantityInputValue(quantityInputValue);
    } else {
      addOrUpdateProducts(modifiedProductData);
      setQuantityInputValue(keyBoardInputValue === "" ? 1 : keyBoardInputValue);
      setTempQuantityInputValue(keyBoardInputValue === "" ? 1 : keyBoardInputValue);
      savingQuantityInput(keyBoardInputValue === "" ? 1 : keyBoardInputValue);
      setQtyShowInputFields(false);
    }
  };

  const incrementQuantity = () => {
    setSelectedQuantity((prev) => parseInt(prev) + 1);
  };

  const decrementQuantity = () => {
    setSelectedQuantity((prev) => {
      const newValue = parseInt(prev) - 1;
      return newValue >= 0 ? newValue : 0;
    });
  };

  const incrementTotalQuantity = () => {
    setSelectedTotalQuantity((prev) => parseInt(prev) + 1);
  };

  const decrementTotalQuantity = () => {
    setSelectedTotalQuantity((prev) => {
      const newValue = parseInt(prev) - 1;
      return newValue >= 0 ? newValue : 0;
    });
  };

  const incrementQuantity1 = () => {
    setKeyBoardInputValue((prev) => parseInt(prev) + 1);
  };

  const decrementQuantity1 = () => {
    setKeyBoardInputValue((prev) => {
      const newValue = parseInt(prev) - 1;
      return newValue >= 1 ? newValue : 1;
    });
  };

  const updateProductWithUOMData = async (product) => {
    let uomData = [];
    if (product.cs_uom_id) {
      await db.productUom
        .where("csUomId")
        .equals(product.cs_uom_id)
        .toArray()
        .then((uom) => {
          if (uom.length > 0) {
            uomData = uom;
          }
        });
    }
    return {
      ...product,
      ediCode: uomData[0]?.ediCode,
      uomData,
      total: product.totalWeight * product.sale_price,
      weight: parseFloat(product.weight),
      totalWeight: parseFloat(product.totalWeight),
    };
  };

  const savingSearchedProductsData = (products) => {
    localStorage.setItem("searchedProductsData", JSON.stringify(products));
  };

  const addOrUpdateProducts = async (products) => {
    const modifiedData = await Promise.all(products.map((product) => updateProductWithUOMData(product)));
    const stockFlag = checkStockData(modifiedData);
    if (stockFlag) {
      setIsStockModal(true);
    }
    updateCartTotals(modifiedData);
    setSearchedProductsData(modifiedData);
    savingSearchedProductsData(modifiedData);
  };

  const savingDisableFlag = (flag) => {
    localStorage.setItem("disableSearch", flag);
  };

  const addBOMLines = async (product) => {
    let modifiedData = product?.bomproducts
      ? product?.bomproducts?.map((bom, index) => ({
          isBom: "Y",
          key: index,
          cs_uom_id: bom.bom_uom,
          uomname: bom.bom_uomname,
          product_name: bom.bomproductname,
          m_product_id: bom.m_bomproduct_id,
          weight: bom.qty,
          totalWeight: bom.qty,
          stock: bom.stock !== null ? bom.stock : 0,
          sale_price: bom.sale_price,
          total: bom.qty * bom.sale_price,
        }))
      : [];
    await addOrUpdateProducts(modifiedData);
    if (modifiedData?.length > 0) {
      savingDisableFlag(true);
      setDisableSearch(true);
    } else {
      setDisableSearch(false);
    }
  };

  const saveDateAndSalePrice = (product) => {
    const currentDate = new Date();
    const formattedDate = currentDate.getDate().toString().padStart(2, "0") + "/" + (currentDate.getMonth() + 1).toString().padStart(2, "0") + "/" + currentDate.getFullYear();
    setFormattedDate(formattedDate);
    savingFormattedDate(formattedDate);
    setSelectedProductSalePrice(product?.sale_price !== null ? product?.sale_price : 0);
    savingSelectedProductSalePrice(product?.sale_price !== null ? product?.sale_price : 0);
  };

  const savingSelectedProduct = (product) => {
    localStorage.setItem("selectedProduct", JSON.stringify(product));
  };

  const savingFormattedDate = (date) => {
    localStorage.setItem("formattedDate", date);
  };

  const savingSelectedProductSalePrice = (price) => {
    localStorage.setItem("selectedProductSalePrice", price);
  };

  const savingPricingMethod = (method) => {
    localStorage.setItem("pricingMethod", method);
  };

  const savingOtherChargesData = (data) => {
    localStorage.setItem("otherChargesData", JSON.stringify(data));
  };

  const savingRemarks = (remarks) => {
    localStorage.setItem("remarks", remarks);
  };

  const savingQuantityInput = (input) => {
    localStorage.setItem("quantityInputValue", input);
  };

  const handleProductSelect = (product) => async () => {
    saveDateAndSalePrice(product);
    if (product?.productmanufacturing && product?.productmanufacturing[0]?.standard_price && product?.productmanufacturing[0]?.standard_price === "Y") {
      savingPricingMethod("Standard Price");
      setPricingMethod("Standard Price");
    }
    let uomData = [];
    if (product.cs_uom_id) {
      await db.productUom
        .where("csUomId")
        .equals(product.cs_uom_id)
        .toArray()
        .then((uom) => {
          if (uom.length > 0) {
            uomData = uom;
          }
        });
    }
    product.uomData = uomData;
    product.ediCode = uomData[0]?.ediCode;
    addBOMLines(product);
    setSelectedProduct(product);
    savingSelectedProduct(product);
    setChooseProduct(false);
  };

  const handleChooseProduct = async () => {
    const params = {
      limit: "50",
      offset: "0",
      searchKey: chooseSearchedProduct || "",
      cs_bunit_id: csBunitId,
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      let chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      setChooseProductsData(chooseProductData);
      setChooseProduct(true);
    }
  };

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      handleReachBottom();
    }
  };

  const handleReachBottom = async () => {
    const params = {
      limit: "50",
      offset: chooseProductsData?.length.toString(),
      searchKey: chooseSearchedProduct || "",
      cs_bunit_id: csBunitId,
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      const chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      setChooseProductsData((prevData) => [...prevData, ...chooseProductData]);
    }
  };

  const fetchSearchedProductData = async (data, bunitId) => {
    let params = { searchKey: data, cs_bunit_id: bunitId };
    let bomQty;
    if (tillData?.posScanConfigs?.length > 0) {
      let newData = data?.toString();
      let posConfig = tillData.posScanConfigs.filter((item) => item.formula !== null && item.scanTrxType === "PR" && item.barcodeLength === newData.length);
      const posConfigItem = posConfig.find((item) => newData.startsWith(item.startsWith));
      if (posConfigItem) {
        const formula = JSON.parse(posConfigItem.formula);
        const productValue = newData.substr(Number(formula.property["product.value"]?.position - 1), Number(formula.property["product.value"]?.length));
        params.searchKey = productValue;
        bomQty = newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length));
      }
    }
    const stringifiedFields = JSON.stringify(params);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);
    const searchedProductQuery = { query: `mutation { executeAPIBuilder(apiBuilderId: "652f64627acdc753d7d58b34", params: ${stringRequest})}` };
    const headers = { "Content-Type": "application/json", Authorization: access_token };
    const serachedProductResponse = await Axios.post(genericUrl, searchedProductQuery, { headers });
    if (serachedProductResponse.status === 200) {
      const searchedProductData = JSON.parse(serachedProductResponse.data.data.executeAPIBuilder);
      if (searchedProductData.length > 1) {
        setSearchedProductsFlag(true);
        setSearchedProductsDrawerData(searchedProductData);
      } else if (searchedProductData.length === 0) {
        notFoundModal();
      } else {
        let flag = false;
        let modifiedSearchedProductData = await Promise.all(
          searchedProductData.map(async (product) => {
            let uomData = [];
            if (product.cs_uom_id) {
              await db.productUom
                .where("csUomId")
                .equals(product.cs_uom_id)
                .toArray()
                .then((uom) => {
                  if (uom.length > 0) {
                    uomData = uom;
                  }
                });
            }
            if (uomData[0]?.decimal === "Y") {
              flag = true;
            }
            return {
              ...product,
              ediCode: uomData[0]?.ediCode,
              uomData,
              weight: bomQty ? parseFloat(parseInt(bomQty) / 1000) : 1,
              totalWeight: bomQty ? parseFloat(parseInt(bomQty) / 1000) : 1,
              total: product?.sale_price !== null ? (product?.sale_price * bomQty ? parseFloat(parseInt(bomQty) / 1000) : 1 * product?.sale_price) : 0,
              sale_price: product?.sale_price !== null ? parseFloat(product?.sale_price) : 0,
            };
          })
        );
        setTempSelectProduct(modifiedSearchedProductData);
        setSearchedProductsData((prevData) => {
          let index = prevData.findIndex((item) => item.m_product_id === modifiedSearchedProductData[0].m_product_id);
          const matchedProduct = prevData.filter((item) => {
            if (modifiedSearchedProductData.length > 0 && item.m_product_id === modifiedSearchedProductData[0].m_product_id) {
              return {
                ...item,
                totalWeight: (item.totalWeight || 0) + (modifiedSearchedProductData[0].totalWeight || 0),
              };
            }
            // return item;
          });

          let concatData = prevData;
          if (index !== -1) {
            modifiedSearchedProductData[0].weight = flag ? prevData[index].weight || 0 : (prevData[index].weight || 0) + (modifiedSearchedProductData[0].weight || 0);
            modifiedSearchedProductData[0].totalWeight = flag
              ? prevData[index].totalWeight || 0
              : (prevData[index].totalWeight || 0) + (modifiedSearchedProductData[0].totalWeight || 0);
            prevData[index] = modifiedSearchedProductData[0];
          } else {
            concatData = [...prevData, ...modifiedSearchedProductData];
          }
          const modifiedProductData = concatData.map((product, index) => ({
            ...product,
            key: index,
            stock: product?.stock !== null ? product?.stock : 0,
          }));
          const stockFlag = checkStockData(matchedProduct.length > 0 ? matchedProduct : concatData);
          if (stockFlag) {
            setIsVisible(true);
            return prevData;
          } else {
            if (!flag) {
              savingSearchedProductsData(modifiedProductData);
              updateCartTotals(modifiedProductData);
            } else {
              setIsWeightPopupVisible(true);
            }
            return flag ? prevData : modifiedProductData;
          }
        });
        setSearchedProduct("");
        setSelectedRowKey(null);
        setShowInputFields(false);
      }
    }
  };

  const barCodeChooseProduct = async (data) => {
    const params = {
      limit: "50",
      offset: "0",
      searchKey: data,
      cs_bunit_id: csBunitId,
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      let chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      if (chooseProductData.length === 1) {
        saveDateAndSalePrice(chooseProductData[0]);
        if (
          chooseProductData[0]?.productmanufacturing &&
          chooseProductData[0]?.productmanufacturing[0]?.standard_price &&
          chooseProductData[0]?.productmanufacturing[0]?.standard_price === "Y"
        ) {
          savingPricingMethod("Standard Price");
          setPricingMethod("Standard Price");
          setSelectedProduct(chooseProductData[0]);
        }
        addBOMLines(chooseProductData[0]);
        savingSelectedProduct(chooseProductData[0]);
        setSelectedProduct(chooseProductData[0]);
        return false;
      } else if (chooseProductData.length === 0) {
        notFoundModal();
        setChooseProductsData(chooseProductData);
        return true;
      } else {
        setChooseProductsData(chooseProductData);
        return true;
      }
    }
  };

  const onBarcodeInput = (data, flag) => {
    setChooseProduct((prevData) => {
      if (prevData === true) {
        barCodeChooseProduct(data).then((result) => {
          setChooseProduct(result);
        });
      } else {
        setDisableSearch((prev) => {
          if (prev === false) {
            if (disableSearch === false) {
              fetchSearchedProductData(data, csBunitId);
            }
          }
          return prev;
        });
      }
      return prevData;
    });
  };

  const handleAddSearchedProduct = (product) => async () => {
    product.weight = 1;
    product.totalWeight = 1;
    product.sale_price = product.sale_price || 0;
    product.total = product.sale_price;
    product.stock = product.stock || 0;
    const modifiedData = await Promise.all([...searchedProductsData, product].map((product) => updateProductWithUOMData(product)));
    const stockFlag = checkStockData(modifiedData);
    if (stockFlag) {
      setIsVisible(true);
    } else {
      await addOrUpdateProducts(modifiedData.map((p, index) => ({ ...p, key: index })));
    }
    setSearchedProductsFlag(false);
  };

  const handleDeleteSearchedProduct = (record) => {
    let updatedData = searchedProductsData.filter((product) => product.key !== record.key).map((product, index) => ({ ...product, key: index }));
    updateCartTotals(updatedData);
    setSearchedProductsData(updatedData);
    savingSearchedProductsData(updatedData);
    setSelectedRowKey(null);
    setShowInputFields(false);
  };

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  const savingProductionTypeData = (type) => {
    localStorage.setItem("productionNextNo", type?.nextno);
    localStorage.setItem("productionName", type?.name);
    localStorage.setItem("productionDocId", type?.cs_doctype_id);
    localStorage.setItem("productionPrefix", type?.prefix);
  };

  const handleProductionTypeData = (value) => {
    db.docTypesData.toArray().then((docTypes) => {
      const requiredDocType = docTypes.filter((docType) => docType?.cs_doctype_id === value);
      setProductionNextNo(requiredDocType[0]?.nextno);
      setProductionName(requiredDocType[0]?.name);
      setProductionDocId(requiredDocType[0]?.cs_doctype_id);
      setProductionPrefix(requiredDocType[0]?.prefix);
      savingProductionTypeData(requiredDocType[0]);
    });
    const productionTypeSelector = document.getElementById("BOM_production_type");
    if (productionTypeSelector) {
      productionTypeSelector?.blur();
    }
  };

  const handleOtherChanges = async () => {
    if (otherChargesData.length === 0) {
      const otherChangesQuery = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"66f408228e3f1f618d89f88b", params: "{}")}`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`,
      };
      const otherChangesResponse = await Axios.post(genericUrl, otherChangesQuery, { headers: headers }, { async: true }, { crossDomain: true });
      if (otherChangesResponse.status === 200) {
        const otherChargesData = JSON.parse(otherChangesResponse?.data?.data?.executeAPIBuilder);
        const updatedOtherChangesData = otherChargesData.map((item) => ({
          ...item,
          weight: "",
        }));
        setOtherChargesData(updatedOtherChangesData);
        setOtherCharges(true);
      }
    } else {
      setOtherCharges(true);
    }
  };

  const removeLocalStorageData = () => {
    const keysToRemove = [
      "selectedProduct",
      "formattedDate",
      "selectedProductSalePrice",
      "pricingMethod",
      "otherChargesData",
      "remarks",
      "productionNextNo",
      "productionName",
      "productionDocId",
      "productionPrefix",
      "productName",
      "totalSalePrice",
      "totalItems",
      "totalQty",
      "subtotal",
      "searchedProductsData",
      "reverseOrderData",
      "disableSearch",
      "quantityInputValue",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  const handleCancel = () => {
    setTempRemarks("");
    setChooseProduct(false);
    setSelectedQuantity(0);
    setSelectedKey(null);
    setSelectedProduct(null);
    setSelectedProductSalePrice(0);
    setChooseProductsData([]);
    setFormattedDate("");
    setSearchedProduct("");
    setChooseSearchedProduct("");
    setSearchedProductsData([]);
    setSearchedProductsFlag(false);
    setSearchedProductsDrawerData([]);
    setSubtotal(0);
    setTotalItems(0);
    setTotalQty(0);
    setTotalSalePrice(0);
    setRemarks("");
    setOtherChargesData([]);
    setTempOtherChargesData([]);
    setQuantityInputValue(1);
    setKeyBoardInputValue(1);
    setTempQuantityInputValue(1);
    setSelectedQuantity(0);
    setSelectedTotalQuantity(0);
    setPricingMethod("");
    setReverseOrderData([]);
    setDisableSearch(false);
    removeLocalStorageData();
  };

  const processBOMOrder = async () => {
    let inputProducts = [];
    searchedProductsData?.map((product) => {
      let obj = {
        mProductId: product.m_product_id,
        csUomId: product.cs_uom_id,
        actualqty: hasReverseOrderData ? -product.totalWeight : product.totalWeight,
        salePrice: hasReverseOrderData ? -product.sale_price : product.sale_price,
        totalPrice: hasReverseOrderData ? -product.total : product.total,
      };
      inputProducts.push(obj);
    });
    let indirectCost = [];
    otherChargesData?.map((charge) => {
      if (charge.weight !== "") {
        let obj = {
          prIndirectcostId: charge.pr_indirectcost_id,
          value: hasReverseOrderData ? -parseInt(charge.weight) : parseInt(charge.weight),
          cost: hasReverseOrderData ? -parseInt(charge.weight) : parseInt(charge.weight),
        };
        indirectCost.push(obj);
      }
    });
    const hardcodedDigit = "4";
    const productSkuValue = selectedProduct?.value.toString().padStart(6, "0").slice(0, 6);
    const finalBarCodeNumber = hardcodedDigit + productSkuValue + productionNextNo;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [day, month, year] = formattedDate.split("/");
    const shortYear = year.slice(-2);
    const newFormattedDate = `${day}-${months[parseInt(month) - 1]}-${shortYear}`;
    const proceedQuery = {
      query: `mutation{
      upsertBOMProduction(order : {
              csBunitId: "${csBunitId}",
              csDoctypeId: "${productionDocId}",
              documentno: ${productionPrefix ? `"${productionPrefix}${productionNextNo}"` : `"${productionNextNo}"`},
              date: "${formattedDate.split("/").reverse().join("-")}",
              mProductId: "${selectedProduct?.m_product_id}",
              qtyproduction: ${hasReverseOrderData ? "-" : ""}${quantityInputValue},
              description: "${remarks}"
              salePrice: ${hasReverseOrderData ? "-" : ""}${totalSalePrice}
              totalPrice: ${hasReverseOrderData ? "-" : ""}${parseFloat((totalSalePrice * quantityInputValue).toFixed(pricePrecision))}
              inputProducts: ${JSON.stringify(inputProducts).replace(/"(\w+)":/g, "$1:")} 
              indirectCost: ${JSON.stringify(indirectCost).replace(/"(\w+)":/g, "$1:")} 
              upc: "${finalBarCodeNumber}"
              batchNo: ${productionPrefix ? `"${productionPrefix}${productionNextNo}-:${newFormattedDate}"` : `"${productionNextNo}-:${newFormattedDate}"`}
              reverse: ${reverseOrderData?.length > 0 ? `"Y"` : `"N"`}
              ${hasReverseOrderData ? `mBatchId: "${reverseOrderData[0]?.mBatchId}"` : ""}
              ${hasReverseOrderData ? `reverseOrderId: "${reverseOrderData[0]?.prOrderId}"` : ""}
          }){
          status
          message
          recordId
      }
  }`,
    };
    if (reverseOrderData?.length === 0) {
      const json = `{
        "printer_type": "thermal",
        "printer_id": "POS123",
        "document": {
          "type": "barcode_label",
          "content": [
            {
              "type": "barcode",
              "value": "${finalBarCodeNumber}",
              "format": "CODE128",
              "align": "center",
              "height": 100,
              "width": 2
            },
            {
              "type": "text",
              "value": "${selectedProduct?.value.toString().padStart(6, "0").slice(0, 6)}/${selectedProduct?.uom}/${selectedProduct?.name}",
              "align": "center",
              "font": "bold",
              "size": "medium"
            },
            {
              "type": "text",
              "value": "Price: ${totalSalePrice}",
              "align": "center",
              "font": "normal",
              "size": "medium"
            },
            {
              "type": "text",
              "value": ${productionPrefix ? `"${productionPrefix}${productionNextNo}-:${newFormattedDate}"` : `"${productionNextNo}-:${newFormattedDate}"`},
              "align": "center",
              "font": "normal",
              "size": "medium"
            }
          ]
        },
        "settings": {
          "copies": ${quantityInputValue},
          "cut_after_print": true,
          "paper_width": "80mm",
          "density": "high"
          }
        }`;
      Axios.post(`${printerURL}labelprinter`, json, {
        headers: {
          "Content-Type": "application/xml; charset=UTF-8",
          Accept: "application/xml",
        },
      })
        .then(() => {
          console.log("BOM Print success!");
        })
        .catch((response) => {
          console.log("BOM Print failed!", response);
        });
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
    };
    try {
      const proceedResponse = await Axios.post(retailUrl, proceedQuery, { headers: headers }, { async: true }, { crossDomain: true });
      if (proceedResponse.status === 200) {
        db.docTypesData
          .where("cs_doctype_id")
          .equals(productionDocId)
          .modify({ nextno: productionNextNo + 1 });
        handleCancel();
        setProductionNextNo((prevNum) => prevNum + 1);
        message.success(`${proceedResponse?.data?.data?.upsertBOMProduction?.recordId} ${proceedResponse?.data?.data?.upsertBOMProduction?.message}`);
      } else {
        message.error("Something is wrong");
      }
    } catch (error) {
      message.error("Something is wrong with proceed query");
      console.error("Proceed query failed", error);
    }
  };

  const handleProceed = async () => {
    Modal.confirm({
      title: "Are you sure you want to proceed?",
      content: "This action will process the BOM order",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        processBOMOrder();
      },
    });
  };

  const handleButtonClick = () => {
    setPopoverVisible(!isPopoverVisible);
  };

  const tillUnlink = async () => {
    // if (posConfigData?.posType === "PRD") {
    const unlinkTillAPI = async () => {
      try {
        let id = tillData.tillAccess.cwrTill.cwrTillID;
        const response = await Axios({
          url: retailUrl,
          method: "POST",
          data: {
            query: `mutation {
                unlinkTill(tillId: "${id}") {
                  status
                  message
                } 
              }`,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${access_token}`,
          },
        });

        if (response.data.data.unlinkTill.status === "200") {
          const keepKey = "";
          localStorage.clear();
          await Promise.all(
            db.tables.map(async (table) => {
              if (table.name !== keepKey) {
                await table.clear();
              }
            })
          );
        }

        localStorage.removeItem("unlink");
        window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
      } catch (error) {
        console.error("Error in unlinkTillAPI:", error);
      }
    };
    await unlinkTillAPI();
    // }
  };

  const BOMProps = {
    showOrderHistory,
    setShowOrderHistory,
    colorVariable,
    CustomerSearch,
    isPopoverVisible,
    setPopoverVisible,
    handleButtonClick,
    searchedProductsData,
    setReverseOrderData,
    checkStockData,
    setIsVisible,
    isStockModal,
    setIsStockModal,
    selectedProduct,
    setSelectedProduct,
    searchedProductsData,
    setSearchedProductsData,
    handleCancel,
    updateCartTotals,
    chooseProduct,
    setChooseProduct,
    chooseSearchedProduct,
    setChooseSearchedProduct,
    chooseProductsData,
    setChooseProductsData,
    inputRef,
    handleProductSelect,
    searchedProductsFlag,
    setSearchedProductsFlag,
    searchedProductsDrawerData,
    handleAddSearchedProduct,
    showRemarks,
    setShowRemarks,
    Keyboard,
    keyboardRef,
    handleShift,
    setLayoutName,
    setOtherCharges,
    setTempOtherChargesData,
    setOtherChargesData,
    otherCharges,
    otherChargesData,
    tempOtherChargesData,
    isVisible,
    setIsVisible,
    stockValidationData,
    handleScroll,
    remarks,
    setRemarks,
    layoutName,
    isWeightPopupVisible,
    setIsWeightPopupVisible,
    selectedKey,
    searchedProduct,
    tempSelectProduct,
    setTempSelectProduct,
    savingSearchedProductsData,
  };

  const handleLogin = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());

    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }
    localStorage.setItem("locked", isLocked);
    const redirectWithIsLocked = `${SSOURL}sign-in/?redirect_uri=${redirectUrl}&username=${username}&readOnly=true`;
    window.location.assign(redirectWithIsLocked);
  };

  return (
    <>
      {!showOrderHistory && !isLocked && <BarCodeComponentForRetail onScan={onBarcodeInput} setProductSearchInput={setSearchedProduct} productSearchInput={searchedProduct} />}
      {!showOrderHistory && !isLocked && (
        <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
          <Row
            style={{
              padding: "0.5% 0",
              height: "9vh",
              alignItems: "center",
              fontSize: "1vw",
            }}
          >
            <Col
              onClick={() => {
                setTillLink(true);
              }}
              style={{ cursor: "pointer" }}
              span={1}
            >
              <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
            </Col>
            <Col span={3}>
              <img
                src={POSLogo}
                onClick={() => (posConfigData?.posType !== "PRD" ? history.push("/pos") : null)}
                style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }}
              />
            </Col>
            <Col
              span={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "1.5em",
                  color: "#0F0718",
                  marginLeft: "5vw",
                  borderRadius: "2px",
                  marginTop: "0.45rem",
                }}
              >
                Production
              </span>
            </Col>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#0F0718",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "1em",
                  paddingLeft: "1vw",
                }}
              >
                Production Order {productionPrefix}
                {productionNextNo}
              </span>
            </Col>
            <Col span={1} />
            {posConfig?.showProductionHistory === "Y" ? (
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "7vw",
                  cursor: totalSalePrice > 0 ? "not-allowed" : "pointer",
                  opacity: totalSalePrice > 0 ? 0.5 : 1,
                }}
                onClick={() => {
                  if (totalSalePrice <= 0) setShowOrderHistory(true);
                }}
              >
                <SalesHistoryIcon color={"#0F0718"} />
              </Col>
            ) : (
              <Col span={2} />
            )}
            <Col
              span={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "1em",
                  color: "#929098",
                  marginLeft: "1vw",
                  marginRight: "1vw",
                }}
              >
                {" "}
                {formattedDateTime}
              </span>
            </Col>
            <Col span={1} />
            <Col
              span={5}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <img
                onClick={() => {
                  setIsLocked(true);
                }}
                src={LockIcon}
                style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  padding: "0.5vw",
                  border: "1px solid #3C424B",
                  borderRadius: "50%",
                }}
              >
                <img src={userIcon} style={{ height: "2.7vh" }} />
              </div>
              <div
                style={{
                  marginLeft: "1vw",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "1em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  {userData.user}
                </span>
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "0.8em",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                </span>
              </div>
              <div style={{ marginLeft: "3%" }}>
                <img
                  src={Online}
                  style={{
                    display: !navigator.onLine && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
                <img
                  src={Ofline}
                  style={{
                    display: navigator.onLine && "none",
                    height: "2.5vh",
                    cursor: "pointer",
                    paddingBottom: "0.5vh",
                  }}
                />{" "}
                &nbsp;
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", height: "91vh" }}>
            <div style={{ width: "63.9%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "0 0 0 1.7%",
                }}
              >
                <div style={{ width: "98.3%" }}>
                  <Input
                    placeholder="Search for products by code/name"
                    id="sm-product-search"
                    className="productSearch-sm"
                    style={{
                      borderRadius: "7px",
                      padding: "0.8vh 0.8vw",
                      borderColor: "#fff",
                    }}
                    suffix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {productSearchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => setProductSearchInput("")} /> : null}
                      </div>
                    }
                    prefix={<img src={SearchIcon} alt="" />}
                    value={searchedProduct}
                    onChange={(ev) => {
                      setSearchedProduct(ev.target.value);
                      localStorage.setItem("productName", ev.target.value);
                    }}
                    disabled={hasReverseOrderData || disableSearch}
                  />
                </div>
              </div>
              <div style={{ padding: "1.5%" }}>
                {hasReverseOrderData ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "29vh",
                      left: "52vw",
                    }}
                  >
                    <span style={{ opacity: "0.8", color: "red", fontSize: "25px" }}>REVERSE</span>
                  </div>
                ) : null}
                <Table
                  className="sm-cart-table-pos"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    borderRadius: "7px",
                    padding: "0px 7px",
                    height: "56.5vh",
                    background: "#fff",
                    pointerEvents: hasReverseOrderData ? "none" : "auto",
                  }}
                  pagination={false}
                  sticky
                  id="sm-cart-table"
                  onRow={(record) => ({
                    onClick: () => {
                      if (!hasReverseOrderData) {
                        if (selectedRowKey === record.key) {
                          setQtyShowInputFields(false);
                          setShowInputFields(!showInputFields);

                          if (showInputFields) {
                            requestAnimationFrame(() => {
                              if (activeTabKey === "1" && qtyInputRef?.current) {
                                qtyInputRef.current.focus();
                                qtyInputRef.current.select();
                              } else if (activeTabKey === "2" && totalQtyRef?.current) {
                                totalQtyRef.current.focus();
                                totalQtyRef.current.select();
                              }
                            });
                          }
                        } else {
                          setSelectedQuantity(record.weight);
                          setSelectedTotalQuantity(record.totalWeight);
                          setSelectedKey(record.key);
                          setIsNewInput(true);
                          setShowInputFields(true);
                          setSelectedRowKey(record.key);
                          setQtyShowInputFields(false);
                          requestAnimationFrame(() => {
                            if (activeTabKey === "1" && qtyInputRef?.current) {
                              qtyInputRef.current.focus();
                              qtyInputRef.current.select();
                            } else if (activeTabKey === "2" && totalQtyRef?.current) {
                              totalQtyRef.current.focus();
                              totalQtyRef.current.select();
                            }
                          });
                        }
                      }
                    },
                  })}
                  rowClassName={(record) => (selectedRowKey === record.key ? "tableSelected" : "tableNonSelected")}
                  columns={[
                    {
                      title: "Item",
                      dataIndex: "product_name",
                      key: "product_name",
                      width: "26%",
                    },
                    {
                      title: "UOM",
                      dataIndex: "ediCode",
                      key: "ediCode",
                      width: "8%",
                    },
                    {
                      title: "Qty/Unit",
                      dataIndex: "weight",
                      key: "weight",
                      width: "8%",
                      align: "center",
                      render: (text, record) => {
                        return <div style={{ textAlign: "center" }}>{record.weight.toFixed(record?.uomData[0]?.stdprecision)}</div>;
                      },
                    },
                    {
                      title: "Total Qty",
                      dataIndex: "totalWeight",
                      key: "totalWeight",
                      width: "9%",
                      align: "center",
                      render: (text, record) => {
                        return <div style={{ textAlign: "center" }}>{record.totalWeight.toFixed(record?.uomData[0]?.stdprecision)}</div>;
                      },
                    },
                    {
                      title: "Unit Price",
                      dataIndex: "sale_price",
                      key: "sale_price",
                      width: "9%",
                      align: "center",
                    },
                    {
                      title: "Total Price",
                      dataIndex: "total",
                      key: "total",
                      width: "12%",
                      align: "center",
                      render: (text, record) => {
                        return <div style={{ textAlign: "center" }}>{record.total.toFixed(pricePrecision)}</div>;
                      },
                    },
                    {
                      title: "Stock Qty",
                      dataIndex: "stock",
                      key: "stock",
                      width: "9%",
                      align: "center",
                      render: (text, record) => {
                        return <div style={{ textAlign: "center" }}>{record.stock.toFixed(record?.uomData[0]?.stdprecision)}</div>;
                      },
                    },
                    {
                      title: "",
                      dataIndex: "",
                      key: "",
                      width: "3%",
                      align: "center",
                      render: (text, record) => (
                        <img
                          src={DeleteProduct}
                          style={{
                            cursor: hasReverseOrderData || disableSearch ? "not-allowed" : "pointer",
                            height: "17px",
                            width: "17px",
                            opacity: hasReverseOrderData || disableSearch ? 0.5 : 1,
                          }}
                          onClick={(e) => {
                            if (!hasReverseOrderData && !disableSearch) {
                              e.stopPropagation();
                              handleDeleteSearchedProduct(record);
                            }
                          }}
                        />
                      ),
                    },
                  ]}
                  dataSource={searchedProductsData}
                />
              </div>
              <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
                <div
                  style={{
                    background: "#fff",
                    height: "23.5vh",
                    width: "60%",
                    marginRight: "1%",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    padding: "0.5vh",
                    fontSize: "1vw",
                  }}
                >
                  <Row style={{ height: "4.5vh", fontSize: "1vw" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        textAlign: "left",
                        fontWeight: "500",
                        color: "#0F0718",
                        marginTop: "2vh",
                      }}
                    >
                      <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}>Subtotal:</p>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "0.8vw",
                        textAlign: "right",
                        justifyContent: "end",
                        color: "#0F0718",
                        fontSize: "1.2em",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        {subtotal.toFixed(pricePrecision)}
                        <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ height: "4.5vh", marginTop: "4vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}>Total Items:</p>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        paddingRight: "0.8vw",
                        textAlign: "right",
                        justifyContent: "end",
                        color: "#0F0718",
                        fontSize: "1.2em",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      &nbsp;
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        {totalItems}
                      </p>
                      <span style={{ marginLeft: "2px" }}></span>
                    </Col>
                  </Row>
                  <Row style={{ height: "4.5vh" }}>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "0.8vw",
                        fontWeight: "500",
                        textAlign: "left",
                        marginTop: "2vh",
                      }}
                    >
                      <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}> Total Qty:</p>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "0.8vw",
                        textAlign: "right",
                        justifyContent: "end",
                        color: "#0F0718",
                        fontSize: "1.2em",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "right",
                          margin: "0",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        {parseFloat(totalQty).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{ height: "23.7vh", width: "40%", fontSize: "1vw" }}
                  onClick={() => {
                    setSelectedRowKey(null);
                    setShowInputFields(false);
                    setQtyShowInputFields(false);
                  }}
                >
                  <div
                    id="sm-cart-total"
                    style={{
                      height: "23.5vh",
                      position: "relative",
                      background: "#fff",
                      borderRadius: "7px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1vw",
                    }}
                  >
                    <p
                      style={{
                        padding: "0.7vw 0 0 1vw",
                        color: "#0F0718",
                        fontSize: "1.3em",
                        fontWeight: "500",
                        height: "4.5vh",
                        paddingRight: "17vw",
                      }}
                    >
                      Unit Price
                    </p>
                    <p
                      style={{
                        color: "#0F0718",
                        fontSize: "2.5em",
                        fontWeight: 600,
                        height: "14.4vh",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                      {(totalSalePrice + totalWeights).toFixed(pricePrecision)}
                      <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "35.6%",
                padding: "0% 0% 0 0.4%",
                borderRadius: "7px",
                paddingBottom: "0",
                height: "89.5vh",
                marginRight: "1%",
                backgroundColor: "#fff",
                position: "relative",
                fontSize: "1vw",
              }}
            >
              {selectedProduct !== null ? (
                <div style={{ position: "relative" }}>
                  <Row>
                    <Col span={6}>
                      <img
                        src={selectedProduct?.imageurl ? selectedProduct?.imageurl : DefaultProductImage}
                        style={{
                          width: "100%",
                          height: "11vh",
                          borderRadius: "5px",
                          marginTop: "4vh",
                          marginLeft: "1.5vw",
                        }}
                      />
                    </Col>
                    <Col span={3} />
                    <Col span={15} style={{ marginTop: "4vh" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "1.2em", fontWeight: 500, color: "#0F0718", textOverflow: "ellipsis", maxWidth: "100%" }}>{selectedProduct?.name}</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ fontSize: "1.1em", fontWeight: 400, color: "#0F0718", marginRight: "8px" }}>Qty:</span>
                          <Button
                            icon={<MinusOutlined style={{ fontSize: "15px" }} />}
                            style={{
                              width: "30px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingRight: 6,
                              marginRight: "15px",
                              paddingLeft: 6,
                              borderRadius: "5px",
                              paddingTop: 0,
                            }}
                            onClick={() => {
                              setTempQuantityInputValue((prevValue) => {
                                const newQuantity = Math.max(1, parseInt(prevValue) - 1);
                                updateQuantityAndProducts(newQuantity);
                                return newQuantity;
                              });
                            }}
                            disabled={hasReverseOrderData}
                          />
                          <Input
                            type="text"
                            value={tempQuantityInputValue}
                            style={{ width: "70px", textAlign: "center" }}
                            onPressEnter={(e) => {
                              e.preventDefault();
                              const finalValue = tempQuantityInputValue === "" ? 1 : Number(tempQuantityInputValue);
                              updateQuantityAndProducts(finalValue);
                              setQtyShowInputFields(false);
                              e.target.blur();
                            }}
                            onFocus={() => {
                              setQtyShowInputFields(true);
                              setIsFirstInput(true);
                              setShowInputFields(false);
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 4) {
                                const sanitizedValue = value.replace(/[^0-9]/g, "");
                                setTempQuantityInputValue(sanitizedValue);
                              }
                            }}
                            disabled={hasReverseOrderData}
                          />
                          <Button
                            icon={<PlusOutlined style={{ fontSize: "15px", color: "#fff" }} />}
                            style={{
                              width: "30px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingRight: 6,
                              marginLeft: "15px",
                              paddingLeft: 6,
                              backgroundColor: "#2F3856",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              setTempQuantityInputValue((prevValue) => {
                                const newQuantity = Math.max(1, parseInt(prevValue) + 1);
                                updateQuantityAndProducts(newQuantity);
                                return newQuantity;
                              });
                            }}
                            disabled={hasReverseOrderData}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Scrollbars style={{ height: "60vh", fontSize: "1vw" }}>
                    <Row style={{ display: "flex", alignItems: "center", marginTop: "3vh" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw" }}>
                        <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Production Type</p>
                      </Col>
                      <Col span={1} />
                      <Col span={13}>
                        <Select
                          value={productionName}
                          id="BOM_production_type"
                          style={{ width: "96%", border: "1px solid #92909880", color: "#0F0718", fontSize: "1.1em" }}
                          onChange={(value) => handleProductionTypeData(value)}
                          disabled={hasReverseOrderData}
                        >
                          {productionTypeData?.map((productionType, index) => {
                            return (
                              <Option key={index} value={productionType?.cs_doctype_id}>
                                {productionType?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center" }}>
                        <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>Production Date</p>
                      </Col>
                      <Col span={1} />
                      <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1em", margin: 0 }}>{formattedDate}</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center", height: "100%" }}>
                        <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718", margin: 0 }}>Other Charges</p>
                      </Col>
                      <Col span={1} />
                      <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                        <PlusOutlined
                          style={{
                            cursor: hasReverseOrderData ? "not-allowed" : "pointer",
                            opacity: hasReverseOrderData ? 0.5 : 1,
                          }}
                          onClick={(e) => {
                            if (!hasReverseOrderData) {
                              handleOtherChanges();
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    {otherChargesData?.some((item) => item.weight) &&
                      otherChargesData.map(
                        (item, index) =>
                          item?.weight && (
                            <Row key={index} style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                              <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center" }}>
                                <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>{item?.name}</p>
                              </Col>
                              <Col span={1} />
                              <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1em", margin: 0 }}>
                                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                  {item?.weight}
                                  <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                                </p>
                              </Col>
                            </Row>
                          )
                      )}
                    <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw" }}>
                        <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718" }}>Remarks</p>
                      </Col>
                      <Col span={1} />
                      <Col span={13} style={{ display: "flex", alignItems: "center" }}>
                        <TextArea
                          style={{ width: "18vw", resize: "none", border: "1px solid #92909880", color: "#0F0718", fontSize: "1em", fontWeight: 500 }}
                          value={remarks}
                          onFocus={() => {
                            setShowRemarks(true);
                            setTempRemarks(remarks);
                          }}
                          disabled={hasReverseOrderData}
                        ></TextArea>
                      </Col>
                    </Row>
                    {selectedProduct?.productmanufacturing &&
                      selectedProduct?.productmanufacturing[0]?.standard_price &&
                      selectedProduct?.productmanufacturing[0]?.standard_price === "Y" && (
                        <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                          <Col span={8} style={{ marginLeft: "1.5vw" }}>
                            <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718", paddingTop: "1rem" }}>Pricing Method</p>
                          </Col>
                          <Col span={1} />
                          <Col span={13} style={{ display: "flex", alignItems: "center" }}>
                            <Select
                              value={pricingMethod}
                              style={{ width: "18vw", border: "1px solid #d9d9d9", borderRadius: "4px" }}
                              placeholder="Select"
                              onChange={(value) => {
                                setPricingMethod(value);
                                savingPricingMethod(value);
                              }}
                            >
                              <Select.Option value="Standard Price">Standard Price</Select.Option>
                              <Select.Option value="BOM Price">BOM Price</Select.Option>
                            </Select>
                          </Col>
                        </Row>
                      )}
                    <Row style={{ marginTop: "1vh", display: "flex", alignItems: "center" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw" }}>
                        <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718", paddingTop: "1rem" }}>Sale Price/Unit</p>
                      </Col>
                      <Col span={1} />
                      <Col span={13} style={{ display: "flex", alignItems: "center" }}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        {(totalSalePrice + totalWeights).toFixed(pricePrecision)}
                        <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col span={8} style={{ marginLeft: "1.5vw" }}>
                        <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718", paddingTop: "1rem" }}>Total Price</p>
                      </Col>
                      <Col span={1} />
                      <Col span={13} style={{ display: "flex", alignItems: "center" }}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        {(totalSalePrice * quantityInputValue + totalWeights * quantityInputValue).toFixed(pricePrecision)}
                        <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>
                  </Scrollbars>
                  {showInputFields && (posConfig?.enableQtyUnitKeypad === "Y" || posConfig?.enableTotalQtyKeypad === "Y") ? (
                    <Tabs
                      defaultActiveKey="1"
                      onChange={(key) => {
                        setIsNewInput(true);
                        setActiveTabKey(key);
                        if (key === "1") {
                          requestAnimationFrame(() => {
                            qtyInputRef.current?.focus();
                            qtyInputRef.current?.select();
                          });
                        } else if (key === "2") {
                          requestAnimationFrame(() => {
                            totalQtyRef.current?.focus();
                            totalQtyRef.current?.select();
                          });
                        } else if (key === "3") {
                          setSelectedRowKey(null);
                          setShowInputFields(false);
                          setSelectedQuantity(0);
                          setSelectedTotalQuantity(0);
                        }
                      }}
                      className="custom-tabs"
                      style={{
                        position: "absolute",
                        top: showInputFields ? "53%" : "100%",
                        left: "0",
                        width: "100%",
                        height: "auto",
                        background: "rgba(255, 255, 255, 0.95)",
                        zIndex: 1000,
                        transition: "top 0.5s ease",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      {posConfig?.enableQtyUnitKeypad === "Y" && (
                        <TabPane tab="Qty/Unit" key="1" className="custom-tabpane">
                          <Row style={{ margin: "0 0 0 0 " }}>
                            <Input
                              ref={qtyInputRef}
                              className="productSearch sm-payment-search totalQtyInput"
                              style={{
                                borderRadius: "0.5vw",
                                height: "6.5vh",
                                margin: "0% 1% 1% 0%",
                                borderRadius: "5px",
                                textAlign: "right",
                                fontWeight: "500",
                                fontSize: "2.5em",
                                borderColor: "#92909880",
                                color: "#2F3856",
                              }}
                              id="sm-amount-input"
                              placeholder="Enter Quantity"
                              value={selectedQuantity}
                              onPressEnter={(e) => {
                                e.preventDefault();
                                handleEnter();
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                const sanitizedValue =
                                  selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y"
                                    ? value.replace(/[^0-9.]/g, "")
                                    : value.replace(/[^0-9]/g, "");
                                setSelectedQuantity(sanitizedValue);
                              }}
                            />
                          </Row>
                          <Row style={{ padding: "0 1% 0 0", marginTop: "1vh" }} gutter={[8, 6]}>
                            <Col span={6}>
                              <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleAmount("1")}>
                                1
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleAmount("2")}>
                                2
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleAmount("3")}>
                                3
                              </button>
                            </Col>
                            <Col span={6}>
                              <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleAmount("x")}>
                                <img src={CancelArrow} alt="back space" style={{ width: "20px", height: "15px" }} />
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleAmount("4")}>
                                4
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleAmount("5")}>
                                5
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleAmount("6")}>
                                6
                              </button>
                            </Col>
                            <Col span={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <button
                                  id="sm-amount-addQuantity"
                                  className="amt-dial-btn-market-qty"
                                  style={{
                                    cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "not-allowed" : "pointer",
                                  }}
                                  onClick={selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? null : decrementQuantity}
                                >
                                  -
                                </button>
                                <button
                                  id="sm-amount-addQuantity"
                                  className="amt-dial-btn-market-qty"
                                  style={{
                                    cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "not-allowed" : "pointer",
                                  }}
                                  onClick={selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? null : incrementQuantity}
                                >
                                  +
                                </button>
                              </div>
                            </Col>
                            <Col span={18}>
                              <Row gutter={[8, 6]}>
                                <Col span={8}>
                                  <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleAmount("7")}>
                                    7
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleAmount("8")}>
                                    8
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmount("9")}>
                                    9
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmount("0")}>
                                    0
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleAmount("00")}>
                                    00
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button
                                    id="sm-amount-button."
                                    className="amt-dial-btn-market"
                                    style={{
                                      cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "pointer" : "not-allowed",
                                    }}
                                    onClick={() => handleAmount(".")}
                                  >
                                    .
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-buttonEnter" className="amt-dial-btn-market2" onClick={handleEnter}>
                                Enter
                              </button>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      {posConfig?.enableTotalQtyKeypad === "Y" && (
                        <TabPane tab="Total Qty" key="2" className="custom-tabpane">
                          <Row style={{ margin: "0 0 0 0 " }}>
                            <Input
                              ref={totalQtyRef}
                              className="productSearch sm-payment-search totalInput"
                              style={{
                                borderRadius: "0.5vw",
                                height: "6.5vh",
                                margin: "0% 1% 1% 0%",
                                borderRadius: "5px",
                                textAlign: "right",
                                fontWeight: "500",
                                fontSize: "2.5em",
                                borderColor: "#92909880",
                                color: "#2F3856",
                              }}
                              onPressEnter={(e) => {
                                e.preventDefault();
                                handleTotalEnter();
                              }}
                              id="sm-total-amount-input"
                              placeholder="Enter Quantity"
                              value={selectedTotalQuantity}
                              onChange={(e) => {
                                const value = e.target.value;
                                const sanitizedValue =
                                  selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y"
                                    ? value.replace(/[^0-9.]/g, "")
                                    : value.replace(/[^0-9]/g, "");
                                setSelectedTotalQuantity(sanitizedValue);
                              }}
                            />
                          </Row>
                          <Row style={{ padding: "0 1% 0 0", marginTop: "1vh" }} gutter={[8, 6]}>
                            <Col span={6}>
                              <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleTotalAmount("1")}>
                                1
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleTotalAmount("2")}>
                                2
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleTotalAmount("3")}>
                                3
                              </button>
                            </Col>
                            <Col span={6}>
                              <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleTotalAmount("x")}>
                                <img src={CancelArrow} alt="back space" style={{ width: "20px", height: "15px" }} />
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleTotalAmount("4")}>
                                4
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleTotalAmount("5")}>
                                5
                              </button>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleTotalAmount("6")}>
                                6
                              </button>
                            </Col>
                            <Col span={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <button
                                  id="sm-amount-addQuantity"
                                  className="amt-dial-btn-market-qty"
                                  style={{
                                    cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "not-allowed" : "pointer",
                                  }}
                                  onClick={selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? null : decrementTotalQuantity}
                                >
                                  -
                                </button>
                                <button
                                  id="sm-amount-addQuantity"
                                  className="amt-dial-btn-market-qty"
                                  style={{
                                    cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "not-allowed" : "pointer",
                                  }}
                                  onClick={selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? null : incrementTotalQuantity}
                                >
                                  +
                                </button>
                              </div>
                            </Col>
                            <Col span={18}>
                              <Row gutter={[8, 6]}>
                                <Col span={8}>
                                  <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleTotalAmount("7")}>
                                    7
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleTotalAmount("8")}>
                                    8
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalAmount("9")}>
                                    9
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalAmount("0")}>
                                    0
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleTotalAmount("00")}>
                                    00
                                  </button>
                                </Col>
                                <Col span={8}>
                                  <button
                                    id="sm-amount-button."
                                    className="amt-dial-btn-market"
                                    style={{
                                      cursor: selectedRowKey !== null && searchedProductsData[selectedKey]?.uomData[0]?.decimal === "Y" ? "pointer" : "not-allowed",
                                    }}
                                    onClick={() => handleTotalAmount(".")}
                                  >
                                    .
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6}>
                              <button id="sm-amount-buttonEnter" className="amt-dial-btn-market2" onClick={handleTotalEnter}>
                                Enter
                              </button>
                            </Col>
                          </Row>
                        </TabPane>
                      )}
                      <TabPane
                        className="custom-tabpane"
                        tab={
                          <>
                            <CloseOutlined
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                                fontSize: "17px",
                                color: "#2F3856",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedRowKey(null);
                                setShowInputFields(false);
                                setSelectedQuantity(0);
                                setSelectedTotalQuantity(0);
                              }}
                            />
                          </>
                        }
                        key="3"
                      ></TabPane>
                    </Tabs>
                  ) : QtyShowInputFields ? (
                    <div
                      style={{
                        position: "absolute",
                        top: QtyShowInputFields ? "56.5%" : "100%",
                        left: "0",
                        width: "100%",
                        height: "auto",
                        background: "rgba(255, 255, 255, 0.95)",
                        zIndex: 1000,
                        transition: "top 0.5s ease",
                      }}
                    >
                      <CloseOutlined
                        style={{
                          position: "absolute",
                          marginTop: "1rem",
                          right: "10px",
                          cursor: "pointer",
                          fontSize: "15px",
                          color: "#2F3856",
                        }}
                        onClick={() => {
                          setQtyShowInputFields(false);
                          setSelectedRowKey(null);
                          setKeyBoardInputValue(quantityInputValue);
                        }}
                      />
                      <Row style={{ margin: "0 0 0 0 " }}>
                        <Input
                          id="sm-qty-input"
                          ref={inputRef}
                          className="productSearch sm-payment-search totalInput"
                          style={{
                            borderRadius: "0.5vw",
                            height: "6.5vh",
                            margin: "8% 1% 1% 0%",
                            borderRadius: "5px",
                            textAlign: "right",
                            fontWeight: "500",
                            fontSize: "2.5em",
                            borderColor: "#92909880",
                            color: "#2F3856",
                          }}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            handleEnterQty();
                          }}
                          onChange={(e) => {
                            setKeyBoardInputValue(e.target.value);
                          }}
                          placeholder="Enter Amount"
                          value={keyBoardInputValue}
                        />
                      </Row>
                      <Row style={{ padding: "0 1% 0 0", marginTop: "1vh" }} gutter={[8, 6]}>
                        <Col span={6}>
                          <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleAmountQty("1")}>
                            1
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleAmountQty("2")}>
                            2
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleAmountQty("3")}>
                            3
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleAmountQty("x")}>
                            <img src={CancelArrow} alt="back space" style={{ width: "20px", height: "15px" }} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleAmountQty("4")}>
                            4
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleAmountQty("5")}>
                            5
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleAmountQty("6")}>
                            6
                          </button>
                        </Col>
                        <Col span={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button id="sm-amount-addQuantity" className="amt-dial-btn-market-qty" onClick={decrementQuantity1}>
                              -
                            </button>
                            <button id="sm-amount-addQuantity" className="amt-dial-btn-market-qty" onClick={incrementQuantity1}>
                              +
                            </button>
                          </div>
                        </Col>
                        <Col span={18}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleAmountQty("7")}>
                                7
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleAmountQty("8")}>
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmountQty("9")}>
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmountQty("0")}>
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleAmountQty("00")}>
                                00
                              </button>
                            </Col>
                            <Col span={8}>
                              <button
                                id="sm-amount-button."
                                className="amt-dial-btn-market"
                                style={{
                                  cursor:
                                    selectedProduct && Array.isArray(selectedProduct.uomData) && selectedProduct.uomData[0] && selectedProduct.uomData[0].decimal === "Y"
                                      ? "cursor"
                                      : "not-allowed",
                                }}
                                onClick={() => handleAmountQty(".")}
                              >
                                .
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-buttonEnter" className="amt-dial-btn-market2" onClick={handleEnterQty}>
                            Enter
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div style={{ paddingTop: "2vh", display: "flex", alignItems: "center", width: "97%", position: "absolute", marginTop: "2vh", fontSize: "1vw" }}>
                      <Button
                        style={{
                          color: "rgb(47, 56, 86)",
                          width: "60%",
                          fontWeight: 500,
                          borderRadius: "7px",
                          fontSize: "1.4em",
                          height: "8vh",
                          marginLeft: "0.7vw",
                          border: "1px solid #92909880",
                        }}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          color: "#fff",
                          width: "90%",
                          fontWeight: 500,
                          borderRadius: "7px",
                          background: "#2F3856",
                          fontSize: "1.4em",
                          height: "8vh",
                          marginLeft: "0.7vw",
                        }}
                        onClick={handleProceed}
                        disabled={searchedProductsData?.length === 0}
                      >
                        Proceed
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ paddingTop: "2.5vh", display: "flex", alignItems: "center" }}>
                  <Button
                    style={{
                      color: "#fff",
                      width: "95%",
                      fontWeight: 500,
                      borderRadius: "7px",
                      background: "#2F3856",
                      fontSize: "1.4em",
                      height: "14vh",
                      marginLeft: "0.7vw",
                    }}
                    onClick={handleChooseProduct}
                  >
                    Choose a Product
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: colorVariable ? colorVariable : "#f3f4f9",
          height: "100vh",
          width: "100%",
          display: isLocked ? "block" : "none",
        }}
      >
        {/* <div style={{display:"grid",marginTop:"3rem",position:"relative"}}>
            <div style={{maxWidth:"27rem",margin:"0 auto",background:"#fff",border:'1px solid #e2e2e2'}}>
               <img src={LockScreen} alt="" />
              <Row >
                <Col>
                <label >Username</label>
                <Input
                style={{width:"100%"}}
                  // className="mb-4 w-full h-[40px] sm:h-[40px] rounded px-3 py-1 text-[#101828] placeholder:text-[#98A2B3] border-[0.5px] border-[#DaDaDa] text-xs ff-inter font-normal outline-none"
                  // required
                  type="text"
                  // placeholder="Enter Email"
                  // value={email}
                  id="username"
                  name="username"
                  // onChange={(ev) => { setEmail(ev.target.value) }}
                />
                </Col>
              </Row>
            </div>
          </div> */}
        <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
          <Col span={6}>
            <img
              src={POSLogo}
              style={{
                height: "2.8vw",
                paddingLeft: "1vw",
                paddingTop: "1vh",
              }}
            />
          </Col>
          <Col
            span={18}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0.5vw",
                border: "1px solid #3C424B",
                borderRadius: "50%",
              }}
            >
              <img src={userIcon} style={{ height: "2.7vh" }} />
            </div>
            <span
              style={{
                color: "#0F0718",
                fontSize: "1vw",
                fontFamily: "Inter",
                padding: "0 2%",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
            </span>
            <div style={{ paddingRight: "2%" }}>
              <img src={navigator.onLine ? Online : Ofline} style={{ height: "3.5vh", cursor: "pointer" }} />
            </div>
            {/* <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{formattedDateTime}</div>
              <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{clock1}</div> */}
          </Col>
        </Row>
        <Row style={{ paddingTop: "17vh" }}>
          <Col span={8} />
          <Col
            span={8}
            style={{
              background: "#fff",
              borderRadius: "5px",
              height: "45vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px #00000033",
            }}
          >
            <img
              src={LockScreen}
              style={{
                marginBottom: "0.2vh",
                marginTop: "-17vh",
                width: "25%",
              }}
              alt=""
            />
            <span style={{ fontSize: "14px", fontWeight: 400 }}>Screen Locked </span>
            <Form layout="vertical" style={{ width: "80%" }}>
              <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                <label style={{ fontWeight: 500, fontSize: "15px" }}>Username</label>
                <Form.Item>
                  <Input
                    value={userData.user}
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontWeight: 500,
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col  style={{ padding: "0 2vw", marginTop: "2.5vh" }}> */}
              {/* <label style={{fontWeight:500,fontSize:"15px"}}>Password</label>
                    <Form.Item  >
                      <Input value={password} type="password" onPressEnter={() => handleLogin()} onChange={(e)=>{setPassword(e.target.value)}} style={{width:"100%",padding:"10px"}} />
                    </Form.Item>
                  </Col> */}
              <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                <Button
                  style={{
                    background: "#2F3856",
                    fontWeight: 500,
                    fontSize: "18px",
                    color: "#fff",
                    width: "100%",
                    height: "7vh",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={handleLogin}
                >
                  Log In
                </Button>
              </Col>
            </Form>
          </Col>

          <Col span={8} />
        </Row>
      </div>

      <BOMOrderHistory {...BOMProps} />
      <BOMModals {...BOMProps} />
      <Drawer
        closable={false}
        placement="bottom"
        visible={chooseProduct}
        closeIcon={null}
        maskClosable={false}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => {
          setChooseProduct(false), setChooseSearchedProduct("");
        }}
        height="85vh"
      >
        <div style={{ position: "relative", padding: "1vh 1vw" }}>
          <img
            src={Close}
            onClick={() => {
              setChooseProduct(false), setChooseSearchedProduct("");
            }}
            style={{
              cursor: "pointer",
              width: "1.5vw",
              position: "absolute",
              top: "2vh",
              right: "0.2vw",
            }}
            alt="Close"
          />
        </div>
        <Row
          gutter={[8, 0]}
          style={{
            marginTop: "2vh",
            borderRadius: "10px",
            paddingLeft: "1.5vw",
            fontSize: "1vw",
            display: "flex,",
            justifyContent: "center",
          }}
        >
          <Col span={22}>
            <p style={{ fontSize: "1.5em", fontWeight: 500, fontFamily: "Inter" }}>Trays ({chooseProductsData?.length})</p>
            <Input
              type="text"
              ref={inputRef}
              placeholder="Search for products by code/name"
              id="giftCardSearchInput"
              style={{
                width: "97%",
                padding: "5px",
                borderRadius: "5px",
                fontSize: "1vw",
                color: "#0F0718",
                height: "6vh",
                marginLeft: "0.105rem",
              }}
              prefix={<img src={SearchIcon} alt="" style={{ height: "2.7vh", marginLeft: "0.3rem" }} />}
              value={chooseSearchedProduct}
              onChange={(ev) => {
                setChooseSearchedProduct(ev.target.value);
                localStorage.setItem("productName", ev.target.value);
              }}
            />
          </Col>
        </Row>
        <Scrollbars className="productionScroll" onScrollFrame={handleScroll}>
          <Row
            style={{
              borderRadius: "10px",
              fontSize: "1vw",
              display: "flex,",
              justifyContent: "center",
            }}
          >
            {chooseProductsData?.map((product) => {
              return (
                <Col
                  span={4}
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0rem 0.5rem",
                    flex: "0 0 100%",
                    marginTop: "2vh",
                    marginRight: "1vw",
                    cursor: "pointer",
                  }}
                  onClick={handleProductSelect(product)}
                >
                  <img src={product?.imageurl !== null ? product?.imageurl : DefaultProductImage} alt="" style={{ margin: "0.7rem 0", height: "6rem" }} />
                  <span style={{ textAlign: "center", margin: "0.7rem 0" }}>
                    <p style={{ margin: 0, fontWeight: 500, fontSize: "1em", color: "#0F0718" }}>{product?.name}</p>
                    <span style={{ fontSize: "0.8em", fontWeight: 500, color: "#0F0718" }}></span>
                  </span>
                </Col>
              );
            })}
          </Row>
        </Scrollbars>
      </Drawer>
      <Drawer
        closable={false}
        placement="bottom"
        visible={searchedProductsFlag}
        closeIcon={null}
        maskClosable={false}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => setSearchedProductsFlag(false)}
        height="85vh"
        title={
          <div style={{ position: "relative", padding: "1vh 1vw" }}>
            <img
              src={Close}
              style={{
                width: "1.5vw",
                position: "absolute",
                top: "2vh",
                right: "0.2vw",
                cursor: "pointer",
              }}
              onClick={() => setSearchedProductsFlag(false)}
              alt="Close"
            />
          </div>
        }
      >
        <Scrollbars className="productionScroll">
          {searchedProductsDrawerData?.map((product) => {
            return (
              <Row gutter={[15, 11]} style={{ width: "98%", backgroundColor: "#fff", marginTop: "2.5vh", height: "13vh", marginLeft: "1vw", borderRadius: "10px" }}>
                <Col span={4}>
                  <img
                    style={{
                      width: "100%",
                      height: "11vh",
                      objectFit: "contain",
                      borderRadius: "5px",
                      marginTop: "1vh",
                      display: "flex",
                      alignItems: "center",
                    }}
                    src={product.imageurl ? product.imageurl : DefaultProductImage}
                    alt=""
                  />
                </Col>
                <Col span={16}>
                  <div style={{ maxWidth: "100%", overflow: "hidden", paddingTop: "1vh" }}>
                    <label
                      style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                      className="productName"
                    ></label>
                  </div>
                  <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                    {`${product?.product_name} - ${product?.product_value}`}
                    <span style={{ display: "block" }}>{`Stock Qty - ${product?.stock ? product?.stock : 0}`}</span>
                  </p>
                </Col>
                <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <span className="productDescription-kiosk">
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    {product?.sale_price ? product?.sale_price : 0}
                    <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> / {product?.uomname}
                  </span>
                  <span
                    style={{ fontSize: "0.8em", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 2.5vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}
                    onClick={handleAddSearchedProduct(product)}
                  >
                    ADD
                  </span>
                </Col>
                <span tabIndex={0}></span>
              </Row>
            );
          })}
        </Scrollbars>
      </Drawer>
      <Drawer
        placement="bottom"
        visible={showRemarks}
        height="53vh"
        maskClosable={false}
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              Add Your Remarks
            </p>
            <img
              src={Close}
              onClick={() => {
                setShowRemarks(false);
                setTempRemarks(remarks);
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingTop: 0 }}
        closable={true}
        onClose={() => {
          setShowRemarks(false);
        }}
        footer={null}
      >
        <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
          <Col span={11}>
            <TextArea
              id="sm-notes-input"
              style={{ resize: "none" }}
              ref={textAreaRef}
              maxLength={200}
              rows={11}
              value={tempRemarks}
              onPressEnter={(e) => {
                e.preventDefault();
                savingRemarks(tempRemarks);
                setRemarks(tempRemarks);
                setShowRemarks(false);
              }}
              onChange={(e) => setTempRemarks(e.target.value)}
            />
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "0.8em",
                color: "#929098",
                fontWeight: 500,
              }}
            >
              Max Characters : 200
            </span>
          </Col>
          <Col span={12} offset={1}>
            <Keyboard
              theme={"hg-theme-default aprvl"}
              keyboardRef={(r) => (keyboardRef.current = r)}
              onKeyPress={(button) => {
                const amountInput = document.getElementById("sm-notes-input");
                if (amountInput) {
                  amountInput?.focus();
                }
                if (button === "{shift}" || button === "{lock}") {
                  handleShift();
                  return;
                }
                if (button === "{number}") {
                  setLayoutName("number");
                  return;
                }
                if (button === "{numbers}") {
                  setLayoutName("numbers");
                  return;
                }
                if (button === "{small}") {
                  setLayoutName("shift");
                  return;
                }
                if (button === "{caps}") {
                  setLayoutName("default");
                  return;
                }
                if (button === "{enter}") {
                  savingRemarks(tempRemarks);
                  setRemarks(tempRemarks);
                  setShowRemarks(false);
                  return;
                }
                if (button === "{space}") {
                  setTempRemarks((prev) => prev + " ");
                } else if (button === "{backspace}") {
                  setTempRemarks((prev) => prev.slice(0, -1));
                } else {
                  setTempRemarks((prev) => prev + button.replace(/[{}]/g, ""));
                }
              }}
              layoutName={layoutName}
              display={{
                "{abc}": "ABC",
                "{smileys}": "\uD83D\uDE03",
                "{back}": "Enter",
                "{numbers}": "123",
                "{number}": "123",
                "{bksp}": "backspace",
                "{backspace}": "⌫",
                "{shift}": "⇧",
                "{tab}": "tab",
                "{lock}": "lock",
                "{enter}": "Enter",
                "{space}": "Space",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
              }}
            />
          </Col>
        </Row>
      </Drawer>
      <Drawer
        closable={true}
        maskClosable={false}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Additional Charges</p>
            <img
              src={Close}
              onClick={() => {
                setOtherCharges(false);
                setTempOtherChargesData([...otherChargesData]);
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={otherCharges}
        className="filter"
        bodyStyle={{ padding: "0 4vw" }}
        onClose={() => {
          setOtherCharges(false);
          setTempOtherChargesData([...otherChargesData]);
        }}
        height="90vh"
      >
        <Table
          className="sm-cart-table-pos"
          style={{
            width: "100%",
            overflowY: "auto",
            border: "none",
            borderRadius: "7px",
            padding: "0px 7px",
            height: "67vh",
            background: "#fff",
            marginTop: "2vh",
          }}
          pagination={false}
          sticky
          dataSource={tempOtherChargesData}
          columns={[
            {
              title: "Charge Name",
              dataIndex: "name",
              key: "name",
              width: "32%",
            },
            {
              title: "Charge Amount",
              dataIndex: "weight",
              key: "weight",
              width: "22%",
              align: "center",
              render: (text, record, index) => {
                return (
                  <Input
                    style={{ width: "10vw", textAlign: "right", border: "1px solid #92909880" }}
                    value={record?.weight}
                    ref={index === 0 ? firstInputRef : null}
                    onPressEnter={(e) => {
                      e.preventDefault();
                      savingOtherChargesData(tempOtherChargesData);
                      setOtherChargesData(tempOtherChargesData);
                      setOtherCharges(false);
                    }}
                    onChange={(ev) => {
                      let updatedWeight = ev.target.value;
                      const sanitizedValue = updatedWeight.replace(/[^0-9.]/g, "");
                      let updatedTempData = tempOtherChargesData.map((item) => (item.name === record.name ? { ...item, weight: sanitizedValue } : item));
                      setTempOtherChargesData(updatedTempData);
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#2F3856",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
            onClick={() => {
              setOtherCharges(false);
              setTempOtherChargesData([...otherChargesData]);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={() => {
              savingOtherChargesData(tempOtherChargesData);
              setOtherChargesData(tempOtherChargesData);
              setOtherCharges(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
      <Drawer
        placement="left"
        width="20%"
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={POSLogo} style={{ height: "2vw" }} />
            {/* <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Add Discount</p> */}
            <img
              src={Close}
              onClick={() => setTillLink(false)}
              // style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }}
              style={{
                marginLeft: "auto",
                // paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        bodyStyle={{ paddingTop: 0 }}
        visible={tillLink}
        onClose={() => setTillLink(false)}
      >
        <Row gutter={[16, 16]} style={{ fontSize: "1vw" }}>
          <Col span={24}>
            <Scrollbars style={{ height: "80vh" }}>
              <Row>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
                <Col
                  onClick={tillUnlink}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                    marginBottom: "2vh",
                  }}
                  span={22}
                >
                  <img src={unlinkTill} style={{ height: "2.5vh" }} alt="" />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                      color: "#0F0718",
                    }}
                  >
                    Unlink Till
                  </span>
                </Col>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
              </Row>
            </Scrollbars>
          </Col>
          {/* ) : (
            ""
          )} */}
          {/* <Col span={24}>
            <Row>
              <Col span={1} />
              <Col style={{ cursor: "pointer" }} span={22}>
                <img src={couponIcon} style={{ height: "2.5vh" }} alt="" />
                <span style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2vw", paddingLeft: "1vw" }}>Coupon Code</span>
              </Col>
            </Row>
          </Col> */}
          <Col
            span={24}
            style={{
              width: "80%",
              borderTop: "1px solid #929098",
              position: "absolute",
              bottom: "2.5vh",
              padding: "1vh 1vw",
            }}
          >
            <Row>
              <Col span={1} />

              <Col
                style={{ cursor: "pointer", paddingTop: "1vh" }}
                onClick={async () => {
                  // if (posConfigData?.posType === "PRD") {
                  const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
                  for (let i = 0; i < cookiesToDelete.length; i++) {
                    const cookie = cookiesToDelete[i];
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    const domain = "domain=" + domainURL;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                  }
                  localStorage.removeItem("tillSession");
                  window.location.assign("/");
                  // }
                  handleCancel();
                }}
                span={20}
              >
                <img src={Logout} alt="" />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    color: "#0F0718",
                  }}
                >
                  Logout
                </span>
              </Col>
              <Col span={24} style={{ position: "absolute", bottom: "5vh", right: "0" }}>
                <p style={{ color: "#0f071840", textAlign: "right", paddingRight: "1vw", fontSize: "0.6rem", fontWeight: 400 }}>v24Q3.4.0</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
      <Modal
        visible={isVisible}
        closable={false}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setIsVisible(false);
              setSelectedRowKey(null);
              setShowInputFields(false);
              setSelectedQuantity(0);
              setSelectedTotalQuantity(0);
            }}
          >
            OK
          </Button>,
        ]}
        width={"45vw"}
        centered
      >
        <p style={{ fontWeight: 500, paddingBottom: "2vh", textAlign: "center", color: "red" }}>
          <WarningOutlined style={{ color: "orange", fontSize: "1.2rem" }} />
          This component cannot be added due to insufficient stock
        </p>
        <Table
          style={{
            width: "100%",
            overflowY: "auto",
            borderRadius: "10px",
            padding: "0",
            height: "25vh",
            background: "#fff",
            maxHeight: "25vh",
            display: "block",
          }}
          size="small"
          pagination={false}
          dataSource={stockValidationData || []}
          sticky
          className="sm-stock-table-BOM"
          columns={[
            {
              title: "Item Name",
              dataIndex: "product_name",
              key: "product_name",
              width: "25%",
              render: (text, record, index) => {
                return <div style={{ textAlign: "left" }}>{record.product_name}</div>;
              },
            },
            {
              title: "Required Qty",
              dataIndex: "weight",
              key: "weight",
              width: "12%",
              render: (text, record) => {
                return (
                  <div style={{ textAlign: "left" }}>
                    {activeTabKey === "1" ? record.weight.toFixed(record?.uomData[0]?.stdprecision) : record.totalWeight.toFixed(record?.uomData[0]?.stdprecision)}
                  </div>
                );
              },
            },
            {
              title: "Stock Qty",
              dataIndex: "stock",
              key: "stock",
              width: "10%",
              render: (text, record) => {
                return (
                  <div style={{ textAlign: "left", color: record.stock - record.weight > 0 ? "#0F0718" : "red" }}>
                    {(record?.stock ?? 0).toFixed(record?.uomData[0]?.stdprecision)}
                  </div>
                );
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default ProductionOrder;
